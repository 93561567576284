import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import {  Dropdown } from "react-bootstrap";
import { useNavigate } from 'react-router';
import { setAuthToken } from '../../../config/api';
import { Link } from 'react-router-dom';
import masgan from "../../../assets/masgan.png";
import { FaUserAlt, FaSignOutAlt, FaList } from "react-icons/fa";
// ** Third Party Components
import 'chart.js/auto'
import { useMediaQuery } from 'react-responsive'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import SidebarMenu from '../../Sidebar/Sidebar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaAccessibleIcon, FaAdjust, FaMailBulk, FaRssSquare, FaStackExchange, FaStackpath, FaStarOfLife, FaTransgender, FaUser } from 'react-icons/fa';
import patrol_user from "../../../assets/patrol_user.jpg";

// init token on axios every time the app is refreshed
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const User = ({setViewSidebar}) => {
  document.title = 'Home Admin';
  let navigate = useNavigate();
  const username = localStorage.getItem("name-admin")
  const userImage = localStorage.getItem("photo-admin")

  const handleChangeName = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.toLowerCase(),
    });
  };

  const [form, setForm] = useState({ 
    name: "",
    desc: "",
    url: "",
  });

//   const handleSubmitName = (e) => {
//     e.preventDefault();
//     setDataMap(form.name);
// };

  const logout = (e)=>{
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

    // ** Context, Hooks & Vars
    // const { colors } = useContext(ThemeColors),
      // { skin } = useSkin(),
      
      const labelColor = '#6e6b7b',
      color = 'black',
      colorWarning = 'black',
      colorPrimary = 'black',
      tooltipShadow = 'rgba(0, 0, 0, 0.25)',
      gridLineColor = 'rgba(200, 200, 200, 0.2)',
      lineChartPrimary = '#666ee8',
      lineChartDanger = '#ff4961',
      warningColorShade = '#ffbd1f',
      warningLightColor = '#FDAC34',
      successColorShade = '#0066CC',
      successColorShadeYear = '#00cc00',
      primaryColorShade = '#836AF9',
      infoColorShade = '#299AFF',
      yellowColor = '#ffe800',
      greyColor = '#4F5D70',
      blueColor = '#2c9aff',
      blueLightColor = '#84D0FF',
      greyLightColor = '#EDF1F4'
  
      // setting Marque Speed
      const setting = {
        speed : 40
      }
      
    const viewSidebar = () => {
      setViewSidebar(true)
    }

    useEffect(() => {
    },[viewSidebar]);

    // const viewSidebar = () => {
    //   localStorage.setItem("open", false)
    // }
    
  return (
  <Row style={{backgroundColor:""}}>
    <div xl='12' sm='12' style={{boxShadow:"10px 0px 10px #BFBFBF", backgroundColor:"black"}}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid" style={{position:"fixed", backgroundColor:"#005A9F"}}>
            {[false].map((expand) => (
            <Navbar key={expand} expand={expand} style={{backgroundColor:"#005A9F"}} bg="#005A9F" expand="lg" variant="dark" color='white' >
                <Container >
                  {/* <Navbar.Brand href="#">Hi, Admin</Navbar.Brand> */}
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} bg="#005A9F" expand="lg" variant="dark" color='white'  />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="start"
                   
                  >
                    <Offcanvas.Header closeButton style={{backgroundColor:"#005A9F", color:"white"}}>
                      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{color:"white"}}>
                        Sidebar
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{backgroundColor:"white"}}>
                      <SidebarMenu/>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
              ))}
              
            <div style={{display:"flex", justifyContent:"end"}}>
            <p style={{marginTop:"15px", paddingLeft:"10px", color:"white"}}>{username}</p>
                <Dropdown>
                  <Dropdown.Toggle style={{backgroundColor:"#005A9F", border:"1px solid #005A9F"}} >
                    <img src={patrol_user} alt="mas" width={40} className="rounded-pill"  
                    style={{boxShadow:"1px 1px 5px #C1C1C1", minHeight:"40px", maxHeight:"40px"}}/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="light" style={{alignItem:"left", marginTop:"7px"}}>
                    {/* <Dropdown.Item as={Link} to="/admin-profile">
                      <FaUserAlt className="me-2" style={{color:"#CC6600"}}></FaUserAlt>
                      <span style={{color:"#CC6600"}}>Profile</span>
                    </Dropdown.Item> */}
                    <Dropdown.Item onClick={logout} >
                      <FaSignOutAlt className="me-2" style={{color:"#CC6600"}}/>
                      <span style={{color:"#CC6600"}}>Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
          </div>
        </nav>  
        </div>
        </Row>
    
  );
}
export default User;