import React, { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem,SubMenu,useProSidebar } from 'react-pro-sidebar';
import {FaDotCircle, FaOdnoklassnikiSquare} from "react-icons/fa";
import {  useNavigate } from "react-router-dom";
import "./Sidebar.css"
import { setAuthToken } from '../../config/api';
import { useMediaQuery } from 'react-responsive'
import Caro1 from "../../assets/logo_kss.png"

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

export default function SidebarHome(){
  const navigate = useNavigate();
  const storageItems = JSON.parse(localStorage.getItem('menus'));
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const urlColor = ""
  const levelUser = localStorage.getItem('level');

  // console.log(location.pathname)
  
  const logout = (e)=>{
    e.preventDefault();  
    localStorage.clear();
    navigate("/");
  };

  const navigateHome = ()=>{
    navigate("/dashboard");
  };

  const modules = [
    {
      "id": "dd74266b-7ddd-4a84-80fc-b7bf7778f8d5",
      "name": "Dashboard",
      "slug_name": "user_previlege",
      "icon_name": "fa fa-shield",
      "number_order": 2,
      "create_by": "2b660ec8-027f-4042-8f29-4bb59a3ecb1c",
      "update_by": null,
      "created_at": "2023-08-30 08:20:00",
      "updated_at": "2023-08-30 08:20:00",
      "menus": [
          {
              "id": "1a730dc7-eede-4dd7-a8b7-22c3e8996ea7",
              "name": "Home",
              "slug_name": "home",
              "show": 1,
              "url": "\/dashboard",
              "icon": "fa fa-shield",
              "color_icon": "blue",
              "number_order": 41,
              "create_by": "39ae2633-2de1-4740-ab24-0c76d2136345",
              "update_by": "39ae2633-2de1-4740-ab24-0c76d2136345",
              "created_at": "2024-03-14 02:13:55",
              "updated_at": "2024-03-14 06:54:07",
              "create_by_data": {
                  "id": "39ae2633-2de1-4740-ab24-0c76d2136345",
                  "code": null,
                  "username": "dwiki",
                  "email": "dwiki_wantara@gratiajm.co.id",
                  "email_verified_at": "2023-08-30 15:17:59",
                  "level": "developer",
                  "extended_user": 0,
                  "created_at": "2023-08-30 08:16:39",
                  "updated_at": "2023-09-23 15:49:25",
                  "photo_profile": "https:\/\/storageapps.signatureanugerah.co.id\/public\/images\/no-profile.png"
              },
              "update_by_data": {
                  "id": "39ae2633-2de1-4740-ab24-0c76d2136345",
                  "code": null,
                  "username": "dwiki",
                  "email": "dwiki_wantara@gratiajm.co.id",
                  "email_verified_at": "2023-08-30 15:17:59",
                  "level": "developer",
                  "extended_user": 0,
                  "created_at": "2023-08-30 08:16:39",
                  "updated_at": "2023-09-23 15:49:25",
                  "photo_profile": "https:\/\/storageapps.signatureanugerah.co.id\/public\/images\/no-profile.png"
              }
          },
      ]
    }, 
    {
        "id": "dd74266b-7ddd-4a84-80fc-b7bf7778f8d5",
        "name": "Patroli",
        "slug_name": "user_previlege",
        "icon_name": "fa fa-user",
        "number_order": 2,
        "create_by": "2b660ec8-027f-4042-8f29-4bb59a3ecb1c",
        "update_by": null,
        "created_at": "2023-08-30 08:20:00",
        "updated_at": "2023-08-30 08:20:00",
        "menus": []
    },
 
  ]

  return (
  <Sidebar backgroundColor="white" className='sidebar' style={{width:"100%",height:"100%",position:"",border:"none", background: "#0B5580"}}>
    <Menu style={{ marginTop: "" }}>
      <div style={{display:"flex", justifyContent:"", backgroundColor:"white", paddingLeft:"30px"}}>
        <div style={{display:"flex"}}>
          <div style={{padding:"10px 0px"}}>
            <img src={Caro1} onClick={navigateHome} className="rounded-pill" style={{width:"80px", height:"70px", cursor:"pointer"}} />
          </div>
          <div style={{color:"#00487F", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div>
              <div style={{display:"flex", alignItems:"center", fontSize:"20px", fontWeight:"bold"}}>
                PATROL
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div style={{display:"flex", justifyContent:"start", padding:"15px 0px", fontFamily:"Poppins", fontWeight:"bold", borderBottom:"2px solid white"}}>
        <div style={{marginLeft:"20px", color:"#00487F"}}>
          General
        </div>
      </div>
      
      {storageItems.map((item, index) => (
        (item.url === "" || item.url == null) ? (
          (item.name === "User Previlege" && levelUser !== "developer") ? (
            <></>
          ) : (
            <SubMenu className='mt-2 submenu-label' key={index} style={{fontFamily:"Poppins", paddingLeft:"", display:"flex", alignItems:"center", height:"5vh", fontWeight:"", color:"#575757", fontSize:"14px"}} label={item.name}  
              icon={<i className={item.icon_name} style={{display:"flex", height:"100%", alignItems:"center", paddingLeft:"10px", marginLeft:"10px", color: item?.color_icon, fontSize:"20px"}}/>}>
              {item.menus.map((itemss) => (
                <MenuItem className="menu-item"  href={itemss.url} style={{height:"5vh", fontSize:"12px", fontWeight:"", fontFamily: "sans-serif", paddingLeft: "55px", backgroundColor:""}}>
                  <FaDotCircle style={{ marginRight: "10px", marginBottom: "3px", fontSize: "10px", color: "#666666"}} />
                  {itemss.name}
                </MenuItem>
              ))}
            </SubMenu>
          )
        ) : (
          <MenuItem className="menu-item" href={item.url} style={{fontFamily: "sans-serif", marginLeft: "0px"}} icon={<i className={item.icon_name} style={{marginLeft: "15px", color:"#666666", fontSize: "20px"}} />}>
            {item.name}
          </MenuItem>
        )
      ))}
    </Menu>
  </Sidebar>
  )
}
