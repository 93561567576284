import { useContext, useEffect, useState } from "react";
import { Routes, useNavigate, } from "react-router-dom";
import { UserContext } from "./context/userContext";
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Auth
import Auth from "./pages/Auth/Auth";

// Admin Pasien Medis Record
import AdminHome from "./pages/Dashboard/Home";

// List Pos Check Point
import ListPosCheckPoint from "./pages/ListPosCheckPoint/Home";

// List Print Out Pos Check Point
import PosCheckPointPrintOut from "./pages/ListPosCheckPoint/PrintOutQrCode";

// List Pos Check Point
import ListCheckPointLog from "./pages/ListCheckPointLog/Home";

// List Menus
import AdminMenus from "./pages/ListMenu/Home";

// List Roles
import AdminRoles from "./pages/ListRole/Home";

// Admin Role Permission
import AdminRolePermision from "./pages/ListRolePermission/Home";

// List Security
import AdminModules from "./pages/ListModule/Home";

// Admin Sub Modules
import AdminSubModules from "./pages/ListSubModule/Home";

// Admin User
import AdminUsers from "./pages/ListUser/Home";

// Admin User
import AdminUserPermission from "./pages/ListUserPermission/Home";

// Not Found Page
import My404Component from "./pages/NotFoundPage/PageNotFound";

function App() {

return (
  <Routes>
      {/* Auth */}
      <Route exact path="/" element={<Auth />} />
    
      {/* Dashboard */}
      <Route exact path="/dashboard" element={<AdminHome />}/>

      {/* Pos Patrol pos check point*/}
      <Route exact path="/pos-check-points" element={<ListPosCheckPoint/>}/>
      <Route exact path="/printout-qrcode-pos-check-points/:id" element={<PosCheckPointPrintOut/>} />

      {/* Pos Patrol pos check point*/}
      <Route exact path="/check-point-logs" element={<ListCheckPointLog/>}/>

      {/* Admin Menus */}
      <Route exact path="/privileges/menus" element={<AdminMenus />} />

      {/* Admin Roles */}
      <Route exact path="/privileges/roles" element={<AdminRoles />} />

      {/* Admin Role Permision */}
      <Route exact path="/privileges/roles/:id/:name" element={<AdminRolePermision/>}/>
      
      {/* Admin Modules */}
      <Route exact path="/privileges/modules" element={<AdminModules />} />

      {/* Admin Sub Modules */}
      <Route exact path="/privileges/sub-modules/:id/:slug_name" element={<AdminSubModules />} />

      {/* Admin User */}
      <Route exact path="/privileges/users" element={<AdminUsers/>} />

      {/* Admin User Permission */}
      <Route exact path="/permissions/:id/:firstname/:lastname" element={<AdminUserPermission/>}/>

      {/* Not Found Page */}
      <Route path="*" element={<My404Component />} />
    </Routes>
  );
}

export default App;
