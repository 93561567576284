import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip,CardBody} from 'reactstrap'
import Logo_Dashboard from '../../assets/logo_kss.png'
import source from '../../assets/signature/logo-dental-login.jpg'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import ShowMoreText from 'react-show-more-text';
import Card from 'react-bootstrap/Card';
import {FaUser, FaBirthdayCake, FaMailBulk, FaTransgender, FaMap, FaAddressCard,FaSave, FaAddressBook, FaUserCircle, FaLevelUpAlt, FaMobile, FaChevronLeft, FaBoxTissue, FaFileMedical, FaPumpMedical, FaClinicMedical, FaBriefcaseMedical, FaHandHoldingMedical, FaUserAlt, FaLock, FaCaretSquareRight} from 'react-icons/fa'
import { useParams } from "react-router";
import Caro1 from '../../assets/17580.jpg'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2'
import "./Login.css"
import ToastError from "../NotificationToast/ToastError"
import ToastSuccess from "../NotificationToast/ToastSuccess"

export default function Login() {
  let navigate = useNavigate();
  document.title = "Patrol Login";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const navigateHome = () => {      
    navigate("/dashboard");
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const { email, password } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Configuration
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      // Data body
      const body = JSON.stringify(form);

      // Insert data for login process
      const response = await API.post("/api/auth/login", body, config);
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('name-admin', response.data.data.user.username)
      localStorage.setItem('photo-admin', response.data.data.user.photo_profile)
      localStorage.setItem('id_admin', response.data.data.user.id);
      localStorage.setItem('level', response.data.data.user.level);
      localStorage.setItem('menus', JSON.stringify(response.data.data.user.modules)? JSON.stringify(response.data.data.user.modules) : "" )
      localStorage.setItem('roles', JSON.stringify(response.data.data.user.roles).length === 0 ? "" : JSON.stringify(response.data.data.user.roles))
      // localStorage.setItem('user_data', JSON.stringify(response.data.data.user))
      // console.log(JSON.parse(response.data.data.user.menus))

      // Checking process
      if (response?.status === 200) {
        // Send data to useContext
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: response.data.data,
        })
        ToastSuccess.fire({
          icon: 'success',
          title: response.data.message,
        })
        // setLoading(false)
        navigate("/dashboard");
        // // Status check
        // if (response.data.data.data.username === "dwiki_wantara" && response.data.data.data.username === "davy" ) {
        //   navigate("/home");
        // } else {
        //   navigate("/");
        // }
      }
    } catch (error) {
      // setLoading(false)
      ToastError.fire({
        icon: 'error',
        title: `${error.response.data.message}`,
      })
    }
    setLoading(false)
  });

  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const dropdownStyle = {
    opacity: isDropdownVisible ? 1 : 0,
    visibility: isDropdownVisible ? 'visible' : 'hidden',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    minWidth: '300px',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    left: isDropdownVisible ? '0' : '-250px',
    transition: 'left 2s ease-in-out, opacity 2s ease-in-out, visibility 2s ease-in-out',
  };

  const navigateRegister = () => {
    navigate("/register-pasien-online");
  };
  
  return (
    <div>
      <div>
        <div className='auth-wrapper auth-cover' >
          <Row>
              <Col xl='12' sm='12'>  
                <Card.Img src={Caro1} alt="Card image" style={{ width: "100%", height: "100vh", }} />
                <Card.ImgOverlay style={{ marginTop: "0px", marginLeft: "0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                  {/* <Col xl="12" style={{ padding: "0px 0px", position: "fixed"}}>
                    <div style={{display: "flex", justifyContent: "end", marginBottom: "350px" }}>
                      <Dropdown />  
                    </div>
                  </Col> */}
                    <div style={{backgroundColor:"white", borderRadius:"5px" , boxShadow:"1px 1px 10px #DADADA"}}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                      <div style={{backgroundColor:"", borderTopLeftRadius:"3px",borderTopRightRadius:"3px", width:"100%", display:"flex", justifyContent:"center"}}>
                        <img  style={{borderRadius:"10px", width:"220px", height:"180px"}} src={Logo_Dashboard} alt='Login Cover' />
                      </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"center"}}>
                      <div style={{backgroundColor:"#D8D8D8", width:"100%", 
                        display:"flex", justifyContent:"center", fontFamily:"Poppins", fontWeight:"bold", color:"white", padding:"5px 0px"}}>
                        PATROL 
                      </div>
                    </div>
                      <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)} style={{display:"flex", justifyContent:"center", marginTop:"30px", padding:"10px 20px"}}>
                        <div>
                          <div className="" style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "1px solid #B9B9B9", padding: "5px", borderRadius: "5px" }}>
                          <div style={{ padding: "0 10px", color: "#B9B9B9" }}>
                            <FaUserAlt />
                          </div>
                            <input 
                              value={email} 
                              name="email" 
                              onChange={handleChange} 
                              autoFocus 
                              type='email' 
                              placeholder='USERNAME' 
                              style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "#B9B9B9", padding: "5px 10px", flex: 1 }} 
                              // className="custom-input"
                            />
                            {/* <style>
                              {`
                                .custom-input::placeholder {
                                  color: #B9B9B9;
                                }
                              `}
                          </style> */}
                          </div>
                          <div className="mt-3" style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "1px solid #B9B9B9", padding: "5px", borderRadius: "5px" }}>
                          <div style={{ padding: "0 10px", color: "#B9B9B9" }}>
                            <FaLock />
                          </div>
                            <input 
                              value={password} 
                              name="password" 
                              onChange={handleChange} 
                              autoFocus 
                              type='password' 
                              placeholder='PASSWORD' 
                              style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "#B9B9B9", padding: "5px 10px", flex: 1 }} 
                              // className="custom-input"
                            />
                            {/* <style>
                              {`
                                .custom-input::placeholder {
                                  color: #B9B9B9;
                                }
                              `}
                          </style> */}
                          </div>
                          <div style={{padding:"0px 0px"}}>
                            <Button className="mt-4" type='submit' color="secondary" block style={{padding:"10px 0px", fontSize:"15px", borderRadius:"5px", backgroundColor:"", color:"white"}}>
                              Sign in
                            </Button>

                            <div className="mt-3" type='submit' color='' block style={{display:"flex", justifyContent:"center",padding:"10px 0px", fontSize:"12px", borderRadius:"5px", color:"##B9B9B9", border:"none"  }}>
                              Belum memiliki akun?
                              <Link to="/register-matel" style={{ marginLeft: "2px", textDecoration: "none" }}>
                                <span className="">Daftar</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Card.ImgOverlay>
              </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
