import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
// import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
  
export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getData, setGetData] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);

  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  // console.log(props)
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    name_menus: "",
    url: "",
    icon: "",
    color_icon: "",
    number_order: "",
    create: ""
  });

  useEffect(() => {
    setForm({
      ...form, 
      name_menus: `${props?.nameUpdate}`,
      url: `${props?.urlUpdate}`,
      icon: `${props?.iconUpdate}`,
      color_icon: `${props?.colorIconUpdate}`,
      number_order: `${props?.numberOrderUpdate}`,
      create: `${props?.showUpdate}`
    });
  }, [props])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
  
      // Insert data for login process
      const response = await API.put(`/api/privileges/menus/${props.id}`, {
        name: form?.name_menus,
        url: form?.url,
        icon: form?.icon,
        color_icon: form?.color_icon,
        number_order: form?.number_order,
        show: `${form.create}` === "true" ? true : false
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  });

  const users = [
    {
    id: "true",
    name: "Show"
    },
    {
      id: "false",
      name: "Hide"
      }
  ]

  return (
    <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:""}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"#545454", fontWeight:"600"}}>
          Update Menu
        </div> 
        <div  style={{flex:"8%",fontSize:"20px",display:"flex",alignItems:"center",justifyContent:"center", color:"#545454"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #838383', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#838383', 
              padding: '0 5px', fontSize: '15px' }}>
              Name
            </label>
            <input autoFocus type='text' name="name_menus" onChange={handleChange} value={form?.name_menus}  
              placeholder='Masukan Nama Menu' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#B9B9B9', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #838383', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#838383', 
              padding: '0 5px', fontSize: '15px' }}>
              Url
            </label>
            <input autoFocus type='text' name="url" onChange={handleChange} value={form?.url}  
              placeholder='Masukan Url Menu' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#B9B9B9', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #838383', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#838383', 
              padding: '0 5px', fontSize: '15px' }}>
              Icon
            </label>
            <input autoFocus type='text' name="icon" onChange={handleChange} value={form?.icon}  
              placeholder='Masukan Icon Menu' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#B9B9B9', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #838383', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#838383', 
              padding: '0 5px', fontSize: '15px' }}>
              Color Icon
            </label>
            <input autoFocus type='text' name="color_icon" onChange={handleChange} value={form?.color_icon}  
              placeholder='Masukan Icon Menu' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#B9B9B9', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #838383', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#838383', 
              padding: '0 5px', fontSize: '15px' }}>
              Number Order
            </label>
            <input autoFocus type='number' name="number_order" onChange={handleChange} value={form?.number_order}  
              placeholder='Masukan Numer Order Menu' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#B9B9B9', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-2" style={{ width:"100%", outline:"none", padding:"10px 0px", display:"flex"}}>
            {users.map((user) => (
              <>
                {form.create === user.id ?
                <label className='label-custom' style={{marginTop:"0px", backgroundColor:"#005A9F", color:"white"}}>
                  <input
                    type="radio"
                    name="create"
                    value={user.id}
                    className="check-btn"
                    onChange={handleChange}
                  />{" "}
                  <span className="text-inner">{user.name}</span>
                </label>
                :
                <label className='label-custom' style={{marginTop:"0px", border:"1px solid #959595"}}>
                  <input
                    type="radio"
                    name="create"
                    value={user.id}
                    className="check-btn"
                    onChange={handleChange}
                  />{" "}
                  <span className="text-inner">{user.name}</span>
                </label>
                }
              </>
            ))}
          </div>


        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
            <Button className="mt-3" type='submit' color='secondary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
              Update
            </Button>
          </div>
        </div>
        </Form>
      </Modal.Body>
    </Modal>
  </div>
    );
  }
