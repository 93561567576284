import { useState, useEffect } from "react";
import { Form, Button } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import "bulma/css/bulma.css";
import LoaderAction from "../../Loader/LoaderAction";
import "../../../index.css";
import { saveAs } from 'file-saver';

export default function ModalRoleAdd(props) {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    id_pos: "",
    name_pos: "",
    image_url: ""
  });

  useEffect(() => {
    if (props?.id) {
      setForm({
        id_pos: `${props?.id}`,
        name_pos: `${props?.nameViewQr || ""}`,
        image_url: `${props?.imageUrl || ""}`
      });
    }
  }, [props]);

  const handleSaveAs = () => {
    console.log("Image URL to save:", form?.image_url);
    fetch(form?.image_url)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${form?.name_pos}.png`);
      })
      .catch(error => console.error('Error saving image:', error));
  };

  const handlePrint = () => {
    const printWindow = window.open("", "PRINT", "height=600,width=800");
    printWindow.document.write(`<html><head><title>${form?.name_pos}</title>`);
    printWindow.document.write('</head><body>');
    printWindow.document.write(`<img src="${form?.image_url}" alt="QR Code" style="width: 250px; height: 250px;" />`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div>
      {loading && <LoaderAction />}
      <Modal {...props} size="" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
        <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "" }}>
          <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#005A9F", fontWeight: "600" }}>
            {form?.name_pos}
          </div>
          <div style={{ flex: "8%", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", color: "#005A9F" }}>
            <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none", backgroundImage: "transparent" }}>
          <Form className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <img src={form?.image_url} style={{ width: '250px', height: '250px' }} alt="QR Code" onError={(e) => { e.target.onerror = null; e.target.src = "default-image-url.png"; }} />
              </div>
            </div>
            <div style={{ padding: "20px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
              <div>
                {/* <Button onClick={handleSaveAs} className="mt-3" type='button' color='info' block style={{ color: "white", padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
                  Save As
                </Button> */}
                {/* <Button onClick={handlePrint} className="mt-3" type='button' color='info' block style={{ color: "white", padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
                  Print
                </Button> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
