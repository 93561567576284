import {  useState, useEffect, useRef } from "react";
import { Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { API } from "../../../config/api";
import { FaTimes} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import swal from "sweetalert";
import LoaderAction from "../../Loader/LoaderAction"
import "../../../index.css"
import ToastError from "../../NotificationToast/ToastError"
import ToastSuccess from "../../NotificationToast/ToastSuccess"
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';

export default function ModalRoleAdd(props) {
  const token = localStorage.getItem("token");
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [qrValue, setQrValue] = useState('');
  const nameInputRef = useRef(null); 

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    name_pos: "",
    type_pos: "",
    location: "",
    latitude: "",
    longitude: "",
    note: "",
  });

  console.log(form)
 
  useEffect(() => {
    if (form.name_pos !== "" && form.location !== "") {
      setQrValue(`${form.name_pos} (${form.location})`);
    }
  }, [form.name_pos, form.location]);

  useEffect(() => {
    if (props.show) {
      nameInputRef.current.focus(); 
    }
  },[])

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name] : e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("/api/pos-check-points/store", {
        name_pos: form?.name_pos,
        type_pos: form?.type_pos,
        location: form?.location,
        latitude: form?.latitude,
        longitude: form?.longitude,
        note: form?.note,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        ToastSuccess.fire({
          icon: 'success',
          title: response.data.message,
        })
        props.GetResponseData();
        props.onHide();
        setLoading(false)
      }

    } catch (error) {
      ToastError.fire({
        icon: 'error',
        title: `${error.response.data.message}`,
      })
      setLoading(false)
    }
  });

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:""}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"#005A9F", fontWeight:"600"}}>
          Tambah Pos Check Point
        </div> 
        <div  style={{flex:"8%",fontSize:"20px",display:"flex",alignItems:"center",justifyContent:"center", color:"#005A9F"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #005A9F', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#005A9F', 
              padding: '0 5px', fontSize: '15px' }}>
              Nama Pos
            </label>
            <input ref={nameInputRef} autoFocus type='text' name="name_pos" onChange={handleChange} value={form?.name_pos}  
              placeholder='Masukan Nama Pos' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#005A9F', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #005A9F', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#005A9F', 
              padding: '0 5px', fontSize: '15px' }}>
              Lokasi
            </label>
            <input autoFocus type='text' name="location" onChange={handleChange} value={form?.location}  
              placeholder='Masukan Lokasi' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#005A9F', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #005A9F', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#005A9F', 
              padding: '0 5px', fontSize: '15px' }}>
              Latitude
            </label>
            <input autoFocus type='text' name="latitude" onChange={handleChange} value={form?.latitude}  
              placeholder='Masukan Latitude' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#005A9F', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #005A9F', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#005A9F', 
              padding: '0 5px', fontSize: '15px' }}>
              Longitude
            </label>
            <input autoFocus type='text' name="longitude" onChange={handleChange} value={form?.longitude}  
              placeholder='Masukan Longitude' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#005A9F', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>

          <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
              backgroundColor: 'transparent', border: '2px solid #005A9F', padding: '5px', borderRadius: '5px',
              cursor: 'pointer', height:"42px", width:""}}>
            <label style={{ position: 'absolute', top: '-12px', left: '10px', backgroundColor: '#fff', color: '#005A9F', 
              padding: '0 5px', fontSize: '15px' }}>
              Note
            </label>
            <input autoFocus type='text' name="note" onChange={handleChange} value={form?.note}  
              placeholder='Masukan Note' style={{ backgroundColor: 'transparent', border: 'none', 
              outline: 'none', color: '#005A9F', padding: '5px 5px 5px 10px', flex: 1, fontSize: '14px',width:"300px" }}/>
            <style>{`input::placeholder { color: #B9B9B9;}`}
            </style>
          </div>
          
          {/* QR Code */}
          {/* {qrValue && (
            <div className="mt-4" style={{ textAlign: 'center' }}>
              <QRCode value={qrValue} />
            </div>
          )} */}

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-3" type='submit' color='info' block style={{color:"white", padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
    </Modal>
    </div> 
  );
}
  