import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaUserAltSlash, FaUserCog, FaUserGraduate, FaAssistiveListeningSystems, FaHouseUser, FaQuestionCircle, FaAngellist, FaFileExcel, FaPlusCircle, FaCog, FaCircle, FaTimesCircle, FaFileArchive, FaBong, FaClipboardCheck, FaHome, FaArrowRight, FaAngleRight, FaChartBar, FaHeadSideCough, FaShieldAlt, FaChessBoard, FaFlagCheckered, FaUserShield, FaSign, FaQrcode} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import ModalAdd from "./ModalAdmin/ModalAdd"

// Modal Role
// import ModalAddMedis from "./ModalAdmin/ModalAdd"
// import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import * as XLSX from "xlsx";
// import ExcelReader from './ExcelRader';
import Caro1 from "../../assets/logo_digitel.png"

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "Patrol Dashboard";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [IconUpdate, setIconUpdate] = useState();
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(2022)
  const [modalAdd, setModalAdd] = useState(false);
  const [idUser, setIdUser] = useState(false);
  const [nameRole, setNameRole] = useState("security");
  const [getDataTotalSecurity, setGetDataToltalSecurity] = useState([]);
  const [getDataTotalPos, setGetDataToltalPos] = useState([]);
  const [getDataTotalCheckPointLog, setGetDataToltalCheckPointLog] = useState([]);
  const [dateCheck, setDateCheck] = useState([]);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseDataSecurity = async () => {
    try {
      const response = await API.get(`/api/security?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&name_role=${nameRole}`,fetchParams)
      if (response?.status === 200) {
        setGetDataToltalSecurity(response.data.pagination.total)
        }
    } catch (error) {
    
    }
  }
  
  const GetResponseDataPos = async () => {
    try {
      const response = await API.get(`/api/pos-check-point-dashboards?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      if (response?.status === 200) {
        setGetDataToltalPos(response.data.pagination.total)
        }
    } catch (error) {
    
    }
  }

  const GetResponseDataCheckPoint = async () => {
    try {
      const currentDate = new Date().toISOString().split('T')[0];
      const response = await API.get(`/api/check-point-log-dashboards?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&date_check=${currentDate}`,fetchParams)
      if (response?.status === 200) {
        setGetDataToltalCheckPointLog(response.data.pagination.total)
        }
    } catch (error) {
    
    }
  }

  useEffect(() => {
    GetResponseDataSecurity()
    GetResponseDataPos()
    GetResponseDataCheckPoint()
  }, [])

  
  const viewModalScan = ( id_user) => {
    setModalAdd(true)
    setIdUser(id_user)
  }

  return (
    <div style={{ backgroundColor: "", marginTop: "15px", marginLeft: "10px", marginRight: "10px", borderRadius: "10px" }}>
      {modalAdd  && <ModalAdd GetResponseDataCheckPoint={GetResponseDataCheckPoint} idUser={idUser} show={modalAdd} onHide={() => setModalAdd(false)} />}
      <Row>
      <Col xl="12" sm="12">
        <div >
          <Row>
            <Col className="mb-2" xl="4" md="6" sm="12">
              <div  style={{ backgroundColor: "white", border: "none", borderRadius: "0px", padding: "10px", width: "100%",  boxSizing: "border-box", margin: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
                  <div style={{ flex: "20%", display: "flex", justifyContent: "center", borderRadius: "10px", alignItems: "center", padding: "10px", backgroundColor: "#B9D8FF" }}>
                    <div style={{ border: "3px solid white", padding: "10px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <FaStreetView style={{ color: "white", fontSize: "24px" }} />
                    </div>
                  </div>
                  <div style={{ flex: "80%", padding: "10px", backgroundColor: "white" }}>
                    <div style={{ fontSize: "14px", color: "#005A9F", fontWeight:"bold" }}>
                      Total Check Point 
                    </div>
                    <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#005A9F", fontWeight: "600" }}>
                    </div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", color: "#005A9F", fontWeight:"bold" }}>
                      {getDataTotalCheckPointLog}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col className="mb-2" xl="4" md="6" sm="12">
              <div  style={{ backgroundColor: "white", border: "none", borderRadius: "0px", padding: "10px", width: "100%",  boxSizing: "border-box", margin: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", borderRadius: "3px", overflow: "hidden" }}>
                  <div style={{ flex: "20%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px", padding: "10px", backgroundColor: "#ECF500" }}>
                    <div style={{ border: "3px solid white", padding: "10px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <FaUserShield style={{ color: "white", fontSize: "24px" }} />
                    </div>
                  </div>
                  <div style={{ flex: "80%", padding: "10px", backgroundColor: "white" }}>
                    <div style={{ fontSize: "14px", color: "#005A9F", fontWeight:"bold" }}>
                      Total Security 
                    </div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", color: "#005A9F", fontWeight:"bold" }}>
                      {getDataTotalSecurity}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col className="mb-2" xl="4" md="6" sm="12">
              <div  style={{ backgroundColor: "white", border: "none", borderRadius: "0px", padding: "10px", width: "100%",  boxSizing: "border-box", margin: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", borderRadius: "3px", overflow: "hidden" }}>
                  <div style={{ flex: "20%", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", borderRadius: "10px", backgroundColor: "#5FF5FF" }}>
                    <div style={{ border: "3px solid white", padding: "10px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <FaSign style={{ color: "white", fontSize: "24px" }} />
                    </div>
                  </div>
                  <div style={{ flex: "80%", padding: "10px", backgroundColor: "white" }}>
                    <div style={{ fontSize: "14px", color: "#005A9F", fontWeight:"bold" }}>
                      Total Pos
                    </div>
                    <div style={{ fontSize: "24px", color: "#005A9F", fontWeight:"bold" }}>
                      {getDataTotalPos}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent: isTabletOrMobile ? "center":"start" }}>
            <div>
              <Button onClick={() => viewModalScan()} className="mt-2" type='submit' color='info' block style={{color:"white", padding: "12px 15px", 
                fontSize: "15px", borderRadius: "5px"}}>
                <FaQrcode/> Scan QR
              </Button>
            </div>
          </div>

        </div>
      </Col>
    </Row>
  </div>
  );
}
