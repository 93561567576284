import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { FaSignOutAlt, FaList } from "react-icons/fa";
import patrol_user from "../../../assets/patrol_user.jpg";

function OffcanvasExample({ sidebarStatus, setSidebarStatus }) {
  let navigate = useNavigate();
  const username = localStorage.getItem("name-admin");
  const userImage = localStorage.getItem("photo-admin");

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  const toggleSidebar = () => {
    setSidebarStatus((prevStatus) => !prevStatus);
  };

  return (
    <div style={{ padding: "0px 0px 0px 25px", backgroundColor: "#F3FFFD", padding: "0px 0px 5px 0px" }}>
      <Row className='match-height' style={{ backgroundColor: "#005A9F" }}>
        <Col xl='12' sm='12' style={{ backgroundColor: "#005A9F", boxShadow: "2px 2px 10px #BFBFBF" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "50%", display: "flex" }}>
              <div onClick={toggleSidebar} style={{ backgroundColor: "#005A9F", display: "flex", alignItems: "center", padding: "0px 20px" }}>
                <FaList style={{ fontSize: "20px", color: "white" }} />
              </div>
            </div>
            <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
              <Dropdown style={{ backgroundColor: "#005A9F" }}>
                <Dropdown.Toggle variant="white" style={{ color: "white", fontFamily:"Poppins", fontSize:"15px", fontWeight:"" }}>
                  <img src={patrol_user} width={40} className="rounded-pill" style={{ minHeight: "40px", maxHeight: "40px", marginRight: "3px" }} />
                  {username}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="light" style={{ alignItem: "left", marginTop: "7px" }}>
                  <Dropdown.Item onClick={logout}>
                    <FaSignOutAlt className="me-2" style={{ color: "#CC6600" }} />
                    <span style={{ color: "#CC6600" }}>Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OffcanvasExample;
