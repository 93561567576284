import { useState, useEffect } from "react";
import { Form, Button } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import "bulma/css/bulma.css";
import LoaderAction from "../../Loader/LoaderAction";
import "../../../index.css";

export default function ModalRoleAdd(props) {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    id_pos: "",
    name_pos: "",
    image_url: ""
  });

  useEffect(() => {
    if (props?.id) {
      setForm({
        id_pos: `${props?.id}`,
        name_pos: `${props?.nameViewQr || ""}`,
        image_url: `${props?.imageUrl || ""}`
      });
    }
  }, [props]);

  const handleDownload = () => {
    console.log("Image URL to download:", form?.image_url);
    const a = document.createElement("a");
    a.href = form?.image_url;
    a.download = form?.name_pos + ".png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "PRINT", "height=600,width=800");
    printWindow.document.write(`<html><head><title>${form?.name_pos}</title>`);
    printWindow.document.write('</head><body>');
    printWindow.document.write(`<img src="${form?.image_url}" alt="QR Code" style="width: 250px; height: 250px;" />`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div>
      {loading && <LoaderAction />}
      <Modal {...props} size="" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
        <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "" }}>
          <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#005A9F", fontWeight: "600" }}>
            {form?.name_pos}
          </div>
          <div style={{ flex: "8%", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", color: "#005A9F" }}>
            <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none", backgroundImage: "transparent" }}>
          <Form className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <img src={form?.image_url} style={{ width: '250px', height: '250px' }} alt="QR Code" onError={(e) => { e.target.onerror = null; e.target.src = "default-image-url.png"; }} />
              </div>
            </div>
            <div style={{ padding: "20px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
              <div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
