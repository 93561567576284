import PrintOutQrCode from "../../assets/background_print2.png";
import { useEffect, useRef, useState } from "react";
import { API } from "../../config/api";
import axios from "axios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import { FaQrcode } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";

export default function NotFoundPage() {
  const myRef = useRef(null);
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getDataPhysicalCheck, setGetDataPhysicalCheck] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const fetchDataRef = useRef();
  const fetchData = async () => {
    try {
      setLoading(true);

      const [physicalCheckResponse] = await axios.all([
        API.get(`/api/pos-check-points/${id}`, fetchParams),
      ]);

      if (physicalCheckResponse.status === 200) {
        setGetDataPhysicalCheck(physicalCheckResponse.data.data);
        handlePrint()
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    fetchDataRef.current();
  }, []);


  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  return (
    <div>
      {/* <button 
        onClick={handleDownloadImage} 
        style={{ 
          fontSize: "17px", 
          color: "#2196F3", 
          backgroundColor: "white", 
          borderRadius: "3px", 
          cursor: "pointer", 
          border: "none", 
          display: "flex", 
          marginRight: "3px",
          marginBottom: "10px",
          visibility: imageLoaded ? 'visible' : 'hidden' // Hide button until image is loaded
        }}
        disabled={!imageLoaded} // Disable button until image is loaded
      >
        <FaQrcode />
      </button> */}
      <div ref={myRef} style={{ width: "210mm", height: "297mm", position: "relative" }}>
        <Card.Img src={PrintOutQrCode} alt="Card image" style={{ width: "100%", height: "100%" }} />
        <Card.ImgOverlay style={{ marginTop: "0px", marginLeft: "0px", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "70px" }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: "30px", fontWeight: "bold", fontFamily: "Poppins" }}>
              {getDataPhysicalCheck?.name_pos}
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img 
                src={getDataPhysicalCheck?.image_url} 
                style={{ width: '350px', height: '350px' }} 
                alt="QR Code" 
                onLoad={() => setImageLoaded(true)} 
              />
            </div>
          </div>
        </Card.ImgOverlay>
      </div>
    </div>
  );
}
